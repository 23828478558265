import Spinner from "react-spinkit";
import styles from "./index.module.css";

function Index() {
  return (
    <div className={styles.spinnerContainer}>
      <Spinner className={styles.spinner} name="cube-grid" color="#FF385C" />
    </div>
  );
}

export default Index;
