import { createSlice } from "@reduxjs/toolkit";
import {
  getQuestions,
  getBins,
  postQuestion,
  updateQuestion,
  deleteQuestion,
  getResult,
  addBin,
  updateBin,
  deleteBin,
  generateSessionId,
  getSessionsList,
} from "./adminActions";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    questions: [],
    bins: [],
    sessionId: null,
    sessionsList: [],
    result: null,
    status: {
      getQuestions: null,
      addQuestion: null,
      updateQuestion: null,
      deleteQuestion: null,
      getBins: null,
      addBin: null,
      updateBin: null,
      deleteBin: null,
      generateSessionId: null,
      getSessionsList: null,
      getResult: null,
    },
  },
  reducers: {
    resetAddQuestion: (state) => {
      state.status.addQuestion = null;
    },
    resetUpdateQuestion: (state) => {
      state.status.updateQuestion = null;
    },
    resetDeleteQuestion: (state) => {
      state.status.deleteQuestion = null;
    },
    resetAddBin: (state) => {
      state.status.addBin = null;
    },
    resetUpdateBin: (state) => {
      state.status.updateBin = null;
    },
    resetDeleteBin: (state) => {
      state.status.deleteBin = null;
    },
    resetSessionId: (state) => {
      state.sessionId = null;
      state.status.generateSessionId = null;
    },
    resetSessionsList: (state) => {
      state.sessionsList = [];
      state.status.getSessionsList = null;
    },
    resetResult: (state) => {
      state.result = null;
      state.status.getResult = null;
    },
  },
  extraReducers: {
    [getQuestions.pending]: (state) => {
      state.status.getQuestions = "loading";
    },
    [getQuestions.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.getQuestions = "error";
      } else {
        state.questions = action.payload.sort(
          (a, b) => parseInt(a.id) - parseInt(b.id)
        );
        state.status.getQuestions = "success";
      }
    },
    [getQuestions.rejected]: (state) => {
      state.status.getQuestions = "error";
    },
    [postQuestion.pending]: (state) => {
      state.status.addQuestion = "loading";
    },
    [postQuestion.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.addQuestion = "error";
      } else {
        state.status.addQuestion = "success";
      }
    },
    [postQuestion.rejected]: (state) => {
      state.status.addQuestion = "error";
    },
    [updateQuestion.pending]: (state) => {
      state.status.updateQuestion = "loading";
    },
    [updateQuestion.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.updateQuestion = "error";
      } else {
        state.status.updateQuestion = "success";
      }
    },
    [updateQuestion.rejected]: (state) => {
      state.status.updateQuestion = "error";
    },
    [deleteQuestion.pending]: (state) => {
      state.status.deleteQuestion = "loading";
    },
    [deleteQuestion.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.deleteQuestion = "error";
      } else {
        state.status.deleteQuestion = "success";
      }
    },
    [deleteQuestion.rejected]: (state) => {
      state.status.deleteQuestion = "error";
    },
    [getBins.pending]: (state) => {
      state.status.getBins = "loading";
    },
    [getBins.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.getBins = "error";
      } else {
        state.bins = action.payload.sort(
          (a, b) => parseInt(a.id) - parseInt(b.id)
        );
        state.status.getBins = "success";
      }
    },
    [getBins.rejected]: (state) => {
      state.status.getBins = "error";
    },
    [addBin.pending]: (state) => {
      state.status.addBin = "loading";
    },
    [addBin.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.addBin = "error";
      } else {
        state.status.addBin = "success";
      }
    },
    [addBin.rejected]: (state) => {
      state.status.addBin = "error";
    },
    [updateBin.pending]: (state) => {
      state.status.updateBin = "loading";
    },
    [updateBin.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.updateBin = "error";
      } else {
        state.status.updateBin = "success";
      }
    },
    [updateBin.rejected]: (state) => {
      state.status.updateBin = "error";
    },
    [deleteBin.pending]: (state) => {
      state.status.deleteBin = "loading";
    },
    [deleteBin.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.deleteBin = "error";
      } else {
        state.status.deleteBin = "success";
      }
    },
    [deleteBin.rejected]: (state) => {
      state.status.deleteBin = "error";
    },
    [generateSessionId.pending]: (state) => {
      state.status.generateSessionId = "loading";
    },
    [generateSessionId.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.generateSessionId = "error";
      } else {
        state.sessionId = action.payload;
        state.status.generateSessionId = "success";
      }
    },
    [generateSessionId.rejected]: (state) => {
      state.status.generateSessionId = "error";
    },
    [getSessionsList.pending]: (state) => {
      state.status.getSessionsList = "loading";
    },
    [getSessionsList.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.getSessionsList = "error";
      } else {
        let receivedSessionsList = [];
        Object.keys(action.payload).forEach(key => {
          receivedSessionsList.push(action.payload[key]);
      })
        state.sessionsList = receivedSessionsList;
        // state.sessionsList = action.payload.sessions.sort((a,b) => new Date(b["timestamp"]["updated"]) - new Date(a["timestamp"]["updated"]));
        state.status.getSessionsList = "success";
      }
    },
    [getSessionsList.rejected]: (state) => {
      state.status.getSessionsList = "error";
    },
    [getResult.pending]: (state) => {
      state.status.getResult = "loading";
    },
    [getResult.fulfilled]: (state, action) => {
      if(action.payload > 299) {
        state.status.getResult = "error";
      } else {
        state.result = action.payload;
        state.status.getResult = "success";
      }
    },
    [getResult.rejected]: (state) => {
      state.status.getResult = "error";
    },
  },
});

export const adminActions = adminSlice.actions;
export const adminReducer = adminSlice.reducer;
