import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// axios.defaults.baseURL = 'https://mbi-backend.mrshah.space/'; 

//async function to get session status and first question or question from where user left off
export const getQuestionsAndSessionStatus = createAsyncThunk(
  "user/sessionStatus",
  async (user) => {
    return axios
      .post(
        "/user/selection/session/", {
          sessionId: user.sessionId,
          firstname: user.firstname,
          lastname: user.lastname,
          dob: user.dob,
          emailid: user.emailId
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("result ", res)
          return res;
        }
      })
      .catch((e) => {
        return e;
      });
  }
);

//async function that posts user answers and gets next question based on the user answers
export const getNextQuestion = createAsyncThunk(
  "user/getNextQuestion",
  async (input) => {
    // console.log("making post call ", input)
    return axios
      .post("/user/selection", {
        sessionId: input.sessionId,
        selection: input.selection
      })
      .then((res) => {
        console.log("received data ", res.data)
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }
);

//async function that update session status 
export const endSession = createAsyncThunk(
  "user/endSession",
  async (sessionId) => {
    return axios
      .put(`/user/selection/session/${sessionId}/status/ended`)
      .then((res) => {
        console.log("received data for put ", res.data)
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }
);
