import React from 'react';
import { useDispatch } from 'react-redux';
// import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../features/auth/authSlice';
import { GoogleLogin } from 'react-google-login';
import Button from '../uiUtils/Button';
// import styles from './index.module.css';

function Index({ darkMode }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //function to handle google login success for admin
  const onSuccess = (res) => {
    dispatch(
      authActions.loginAdmin({ tokenId: res.tokenId, fullname: res.pv.zf })
    );
    navigate('/admin');
  };

  // function to handle google login failure for admin
  const onFailure = (res) => {
    console.log('Logout successful ', res);
  };

  // //function to handle user login
  // const loginUser = () => {
  //   dispatch(authActions.loginUser());
  // };

  //function to handle admin login without google login
  // const loginAdmin = () => {
  //   dispatch(authActions.loginAdmin());
  // };

  return (
    <section className={darkMode ? "hero is-fullheight has-background-grey" : "hero is-fullheight has-background-light"} style={{width: "100vw"}}>
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-4-desktop is-4-widescreen">
              {/* <form action="" className="box"> */}
              <div className={darkMode ? "box has-text-centered has-background-light" : "box has-text-centered has-background-grey-lighter"}>
                <p className={
                        darkMode
                          ? 'has-text-dark is-size-3'
                          : 'has-text-dark is-size-3'
                      }>Please login to begin</p>
                  <GoogleLogin
                    clientId="462340557471-bkvaqse20pqjtsbo4shar68gocuqusrf.apps.googleusercontent.com"
                    buttonText="Login as Admin!"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                  cookiePolicy={'single_host_origin'}
                  // className={
                  //   darkMode
                  //     ? 'button has-background-primary-dark has-text-light'
                  //     : 'button is-primary has-text-dark'
                  // }
                    render={(renderProps) => (
                      <Button type="success" click={renderProps.onClick} darkMode={darkMode} style={{margin: "10% auto"}}>
                        Login as Admin!
                      </Button>
                    )}
                  />
                </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    // <div className={styles.container}>
    //   <div className={styles.loginContainer}>
    //     <p className={styles.paragraph}>Please login to begin</p>
    //     <div style={{ display: "flex", justifyContent: "space-evenly" }}>
    //       <GoogleLogin
    //         clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
    //         buttonText="Login as Admin!"
    //         onSuccess={onSuccess}
    //         onFailure={onFailure}
    //         cookiePolicy={'single_host_origin'}
    //         render={renderProps => (
    //           <Button type="success" click={renderProps.onClick}>Login as Admin!</Button>
    //         )}
    //       />
    //     </div>
    //   </div>
    // </div>
  );
}

export default Index;
