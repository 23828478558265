import { createSlice } from "@reduxjs/toolkit";
import { getQuestionsAndSessionStatus, getNextQuestion, endSession } from "./userActions";

const userSlice = createSlice({
  name: "user",
  initialState: {
    sessionDetails: null,
    status: {
      getQuestionsAndSessionStatus: null,
      getNextQuestion: null,
      endSession: null
    }
  },
  reducers: {
    //function to reset session details and other statuses, once all questions are answered
    //and user goes to homepage
    resetSession: (state) => {
      state.sessionDetails = null;
      state.status.getQuestionsAndSessionStatus = null;
      state.status.getNextQuestion = null;
      state.status.endSession = null;
    },
  },
  extraReducers: {
    //get session status and first question or question from where user left off
    [getQuestionsAndSessionStatus.pending]: (state) => {
      state.status.getQuestionsAndSessionStatus = "loading";
    },
    [getQuestionsAndSessionStatus.fulfilled]: (state, action) => {
      if (action.payload.status === 200) {
        state.sessionDetails = {
          ...action.payload.data,
          questions: action.payload.data.questions
        }
        state.status.getQuestionsAndSessionStatus = "success";
      } else {
        //TODO: if the error for wrong session id is different, split
        // the two error messgaes for 
        //1. wrong session id
        //2. server side error
        state.sessionDetails = action.payload.message;
        state.status.getQuestionsAndSessionStatus = "error";
      }
    },
    [getQuestionsAndSessionStatus.rejected]: (state) => {
      state.status.getSessionStatus = "error";
    },
    //get next question based on the user answers
    [getNextQuestion.pending]: (state) => {
      state.status.getNextQuestion = "loading";
    },
    [getNextQuestion.fulfilled]: (state, action) => {
      state.sessionDetails = action.payload
      state.status.getNextQuestion = "success";
    },
    [getNextQuestion.rejected]: (state) => {
      state.status.getNextQuestion = "error";
    },
    //get session status and first question or question from where user left off
    [endSession.pending]: (state) => {
      state.status.endSession = "loading";
    },
    [endSession.fulfilled]: (state, action) => {
      if (action.payload.status === 200) {
        state.sessionDetails = {
          ...action.payload.data
        }
        state.status.endSession = "success";
      } else {
        //TODO: if the error for wrong session id is different, split
        // the two error messgaes for 
        //1. wrong session id
        //2. server side error
        state.sessionDetails = action.payload.message;
        state.status.endSession = "error";
      }
    },
  },
});

export const userActions = userSlice.actions; //export actions mentioned in reducers (e.g. resetSession)
export const userReducer = userSlice.reducer; //export the reducer created by the slice which is used by store
