import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// axios.defaults.baseURL = 'https://mbi-backend.mrshah.space/'; 

export const getQuestions = createAsyncThunk("admin/getQuestions", async (data) => {
  const { headers } = data;
  return axios
    .get("/admin/question", { headers })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
      return e.response.status;
    });
});

export const postQuestion = createAsyncThunk(
  "admin/postQuestion",
  async (data) => {
    const { question, headers } = data;
    return axios
      .post("/admin/question", question, { headers })
      .then((res) => {
        if (res.data.status === 200) {
          return res.data;
        }
      })
      .catch((e) => {
        console.log(e);
        return e.response.status;
      });
  }
);

export const updateQuestion = createAsyncThunk(
  "admin/updateQuestion",
  async (data) => {
    const { question, headers } = data;
    return axios
      .put("/admin/question/" + question.id, { question }, { headers })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((e) => {
        console.log(e);
        return e.response.status;
      });
  }
);

export const deleteQuestion = createAsyncThunk(
  "admin/deleteQuestion",
  async (data) => {
    const { question, headers } = data;
    return axios
      .delete("/admin/question/" + question.id, { headers })
      .then((res) => {
        console.log("res ",res);
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((e) => {
        console.log(e);
        return e.response.status;
      });
  }
);

export const getBins = createAsyncThunk("admin/getBins", async (data) => {
  const { headers } = data;
  return axios
    .get("/admin/bin", { headers })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e.response.status);
      return e.response.status;
    });
});

export const addBin = createAsyncThunk("admin/addBin", async (data) => {
  const { bin, headers } = data;
  return axios
    .post("/admin/bin", bin, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
      return e.response.status;
    });
});

export const updateBin = createAsyncThunk("admin/updateBin", async (data) => {
  const { bin, headers } = data;
  return axios
    .put("/admin/bin/" + bin.id, { bin }, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
      return e.response.status;
    });
});

export const deleteBin = createAsyncThunk("admin/deleteBin", async (data) => {
  const { bin, headers } = data;
  return axios
    .delete("/admin/bin/" + bin.id, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
      return e.response.status;
    });
});

export const generateSessionId = createAsyncThunk(
  "admin/generateSessionId",
  async (data) => {
    const { headers } = data;
    return axios
      .post("/admin/selection/session", 1, { headers })
      .then((res) => {
        return res.data.sessionId;
      })
      .catch((e) => {
        console.log(e);
        return e.response.status;
      });
  }
);

export const getSessionsList = createAsyncThunk(
  "admin/getSessionsList",
  async (data) => {
    const { headers } = data;
    return axios
    .get("/admin/selection/session", { headers })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
      return e.response.status;
    })
  }
)

export const getResult = createAsyncThunk(
  "admin/getResult",
  async (data) => {
    const { sessionId, headers } = data;
    return axios
      .get("/user/result/" + sessionId, { headers })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        return e.response.status;
      });
  }
);
