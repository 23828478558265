import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from '../Login';
import Spinner from '../uiUtils/Spinner';
import Home from '../Home';
// const AdminSubOptions = lazy(() => import('../AdminSubOptions'));
const AddQuestion = lazy(() => import('../AddQuestion'));
const EditQuestion = lazy(() => import('../EditQuestion'));
const AddBin = lazy(() => import('../AddBin'));
const EditBin = lazy(() => import('../EditBin'));
const CreateSession = lazy(() => import('../CreateSession'));
const ShowResult = lazy(() => import('../ShowResult'));
const ShowSessionsList = lazy(() => import('../SessionsList'));
const Settings = lazy(() => import('../Settings'));
const Payments = lazy(() => import('../Payments'));
const Balance = lazy(() => import('../Balance'));
const TxnHistory = lazy(() => import('../TxnHistory'));
// const User = lazy(() => import("../User"));
const UserSession = lazy(() => import('../UserSession'));
const UserConfirmation = lazy(() => import('../UserConfirmation'));
// const AddDomain = lazy(() => import("../AddDomain"));
// const SelectDomain = lazy(() => import("../SelectDomain"));
// const Logout = lazy(() => import("../Logout"));

function Index({ showMenu, darkMode }) {
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  return (
    <>
        {isAdmin ? (
          <Routes>
          <Route element={<Suspense fallback={<Spinner />}><Home showMenu={showMenu} darkMode={darkMode} /> </Suspense>} path="/" exact />
          <Route
              path="/settings"
              exact
              element={<Suspense fallback={<Spinner />}><Settings darkMode={darkMode} /> </Suspense>}
            />
            <Route
              element={<Suspense fallback={<Spinner />}><AddQuestion showMenu={showMenu} darkMode={darkMode} /> </Suspense>}
              path="/addquestion"
              exact
            />
            <Route
              element={<Suspense fallback={<Spinner />}><EditQuestion showMenu={showMenu} darkMode={darkMode}/> </Suspense>}
              path="/editquestion"
              exact
            />
            <Route
              element={<Suspense fallback={<Spinner />}><AddBin showMenu={showMenu} darkMode={darkMode}/> </Suspense>}
              path="/addbin"
              exact
            />
            <Route
              element={<Suspense fallback={<Spinner />}><EditBin showMenu={showMenu} darkMode={darkMode} /> </Suspense>}
              path="/editbin"
              exact
            />
            <Route
              element={<Suspense fallback={<Spinner />}><CreateSession showMenu={showMenu} darkMode={darkMode} /> </Suspense>}
              path="/createsession"
              exact
            />
            <Route
              element={<Suspense fallback={<Spinner />}><ShowSessionsList showMenu={showMenu} darkMode={darkMode} /></Suspense>} 
              path="/result"
              exact
            />
            <Route
              path="/result/:sessionId"
              exact
              element={<Suspense fallback={<Spinner />}><ShowResult showMenu={showMenu} darkMode={darkMode} /> </Suspense>} 
            />
            <Route
              path="/payments"
              exact
              element={<Suspense fallback={<Spinner />}><Payments showMenu={showMenu} darkMode={darkMode} /> </Suspense>}
          />
          <Route
              path="/balance"
              exact
              element={<Suspense fallback={<Spinner />}><Balance showMenu={showMenu} darkMode={darkMode} /> </Suspense>}
          />
          <Route
              path="/txnhistory"
              exact
              element={<Suspense fallback={<Spinner />}><TxnHistory showMenu={showMenu} darkMode={darkMode} /> </Suspense>}
          />
          <Route path="/confirmation" element={<Suspense fallback={<Spinner />}><UserConfirmation darkMode={darkMode} /></Suspense>} />
            <Route path="/" element={<Suspense fallback={<Spinner />}><Home showMenu={showMenu} darkMode={darkMode} /></Suspense>} />
            <Route path="*" element={<Suspense fallback={<Spinner />}><Home showMenu={showMenu} darkMode={darkMode} /></Suspense>} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/session/:sessionId" exact element={<Suspense fallback={<Spinner />}><UserSession darkMode={darkMode} /></Suspense>} />
            <Route path="/login" element={<Suspense fallback={<Spinner />}><Login darkMode={darkMode} /></Suspense>} />
            <Route path="/" element={<Suspense fallback={<Spinner />}><Home showMenu={showMenu} darkMode={darkMode} /></Suspense>} />
            <Route path="*" element={<Suspense fallback={<Spinner />}><Home showMenu={showMenu} darkMode={darkMode} /></Suspense>} />
          </Routes>
        )}
    </>
  );
}

export default Index;
