import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './index.module.css';

const Index = ({ darkMode }) => {
  const location = useLocation();  
  
  return (
    <aside
      className={
        darkMode
          ? `menu is-one-quarter has-background-grey ${styles.sidebarScrollbarDark}`
          : `menu is-one-quarter has-background-light ${styles.sidebarScrollbarLight}`
      }
      // className={`${showMenu} ? (${darkMode} ? menu is-one-quarter has-background-grey : menu is-one-quarter has-background-light) : (${darkMode} ? menu has-background-grey : menu has-background-light)`}
      style={
        {
          width: '15vw',
          borderRight: darkMode ? '1px solid #eee' : '1px solid #bbb',
          height: '100vh',
          paddingTop: '1%',
          overflowY: 'auto',
          // overflowX: 'hidden'
        }
        // : {
        //     width: '2vw',
        //     borderRight: '1px solid #ccc',
        //     height: '100vh',
        //   }
      }
    >
      <p className={darkMode ? "menu-label is-size-5 m-0 mt-3 pl-2 has-text-light has-text-weight-semibold" : "menu-label is-size-5 m-0 mt-3 pl-2 has-text-dark has-text-weight-semibold"}>General</p>
      <ul className={'menu-list pl-3'}>
        <li>
          <Link to="/" className={darkMode ? location.pathname === '/' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Dashboard</Link>
        </li>
        <li>
          <Link to="/settings" className={darkMode ? location.pathname === '/settings' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/settings' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Settings</Link>
        </li>
      </ul>
      <p className={darkMode ? "menu-label is-size-5 m-0 mt-3 pl-2 has-text-light has-text-weight-semibold" : "menu-label is-size-5 m-0 mt-3 pl-2 has-text-dark has-text-weight-semibold"}>Question</p>
      <ul className="menu-list pl-3">
        <li>
          <Link to="/addquestion" className={darkMode ? location.pathname === '/addquestion' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/addquestion' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Add New Question</Link>
        </li>
        <li>
          <Link to="/editquestion" className={darkMode ? location.pathname === '/editquestion' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/editquestion' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Edit Existing Question</Link>
        </li>
      </ul>

      <p className={darkMode ? "menu-label is-size-5 m-0 mt-3 pl-2 has-text-light has-text-weight-semibold" : "menu-label is-size-5 m-0 mt-3 pl-2 has-text-dark has-text-weight-semibold"}>Bin</p>
      <ul className="menu-list pl-3">
        <li>
          <Link to="/addbin" className={darkMode ? location.pathname === '/addbin' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/addbin' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Add New Bin</Link>
        </li>
        <li>
          <Link to="/editbin" className={darkMode ? location.pathname === '/editbin' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/editbin' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Edit Existing Bin</Link>
        </li>
      </ul>

      <p className={darkMode ? "menu-label is-size-5 m-0 mt-3 pl-2 has-text-light has-text-weight-semibold" : "menu-label is-size-5 m-0 mt-3 pl-2 has-text-dark has-text-weight-semibold"}>Result</p>
      <ul className="menu-list pl-3">
        <li>
          <Link to="/createsession" className={darkMode ? location.pathname === '/createsession' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/createsession' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Create New Session</Link>
        </li>
        <li>
          <Link to="/result" className={darkMode ? location.pathname === '/result' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/result' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Show Result</Link>
        </li>
      </ul>

      <p className={darkMode ? "menu-label is-size-5 m-0 mt-3 pl-2 has-text-light has-text-weight-semibold" : "menu-label is-size-5 m-0 mt-3 pl-2 has-text-dark has-text-weight-semibold"}>Transactions</p>
      <ul className="menu-list pl-3">
      <li>
          <Link to="/payments" className={darkMode ? location.pathname === '/payments' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/payments' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Payments</Link>
        </li>
        <li>
          <Link to="/balance" className={darkMode ? location.pathname === '/balance' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/balance' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Balance</Link>
        </li>
        <li>
          <Link to="/txnhistory" className={darkMode ? location.pathname === '/txnhistory' ? `has-text-light ${styles.backgroundDark} is-active` : `has-text-light ${styles.backgroundDark}` : location.pathname === '/txnhistory' ? `has-text-dark ${styles.backgroundLight} is-active` : `has-text-dark ${styles.backgroundLight}`}>Transaction History</Link>
        </li>
      </ul>
    </aside>
  );
};

export default Index;
