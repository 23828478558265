import React from 'react';
import './index.css';

function Index({
  type,
  Icon,
  click,
  location,
  children,
  disabled,
  title,
  darkMode,
  style,
}) {
  
  let styling = '';
  if (darkMode) {
    if (disabled) {
      styling = 'button has-background-grey-dark has-text-light m-2';
    } else {
      if (type === 'success') {
        styling = 'button has-background-success-dark has-text-light m-2';
      } else if (type === 'cancel') {
        styling = 'button has-background-info-dark has-text-light m-2';
      } else {
        styling = 'button has-background-danger-dark has-text-light m-2';
      }
    }
  } else {
    if (disabled) {
      styling = 'button has-background-grey-light has-text-dark m-2';
    } else {
      if (type === 'success') {
        styling = 'button has-background-success has-text-dark m-2';
      } else if (type === 'cancel') {
        styling = 'button has-background-info has-text-dark m-2';
      } else {
        styling = 'button has-background-danger has-text-dark m-2';
      }
    }
  }

  if (Icon) {
    if (location && location === 'after') {

      return (
        <button
          style={style}
          className={`${styling}`}
          onClick={click}
          disabled={disabled}
          title={title}
        >
          {Icon ? (
            <span>
              {children} <Icon className="icon" />
            </span>
          ) : (
            children
          )}
        </button>
      );
    } else {
      return (
        <button
          style={style}
          className={`${styling}`}
          onClick={click}
          disabled={disabled}
          title={title}
        >
          {Icon ? (
            <span>
              <Icon className="icon" /> {children}
            </span>
          ) : (
            children
          )}
        </button>
      );
    }
  } else {
    return (
      <button
        style={style}
        className={`${styling}`}
        onClick={click}
        disabled={disabled}
        title={title}
      >
        {children}
      </button>
    );
  }
}

export default Index;
