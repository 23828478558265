import { configureStore } from "@reduxjs/toolkit";
import { adminReducer } from "../features/admin/adminSlice";
import { authReducer } from "../features/auth/authSlice";
import { userReducer } from "../features/user/userSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    admin: adminReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
