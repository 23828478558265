import React from 'react';
import Navigation from './components/Navigation';
// import CompanyLogo from './Precise_Mind_Report_1.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import { FaUser as User } from 'react-icons/fa';
import { FaChevronDown as DropdownIcon } from 'react-icons/fa';
import 'bulma/css/bulma.css';
import { authActions } from './features/auth/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
// import Button from './components/uiUtils/Button';
import { useState } from 'react';
import Menu from './components/Menu';
import Button from './components/uiUtils/Button';
import './App.css';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const name = useSelector((state) => state.auth.name);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const location = useLocation();  

  const onLogoutSuccess = () => {
    dispatch(authActions.logoutAdmin());
    // navigate("/");
    setTimeout(() => navigate('/'), 500);
  };

  //function to handle google login success for admin
  const onLoginSuccess = (res) => {

    dispatch(
      authActions.loginAdmin({ tokenId: res.tokenId, fullname: res.profileObj.givenName + " " + res.profileObj.familyName })
    );
    
    navigate('/admin');

  };

  // function to handle google login failure for admin
  const onLoginFailure = (res) => {
    console.log('Logout successful ', res);
  };

  return (
    // <div className="app">
    <>
      <nav
        className={
          darkMode
            ? 'navbar is-transparent has-background-black'
            : 'navbar is-transparent has-background-white'
        }
        style={
          darkMode
            ? { boxShadow: '0 2px 18px 0 hsl(0, 0%, 35%)' }
            : { boxShadow: '0 2px 18px 0 hsl(0, 0%, 75%)' }
        }
      >
        <div className="navbar-brand" style={{ maxHeight: '100vh' }}>
          <Link
            className="navbar-item"
            to="/"
            style={{ maxWidth: '55vw', maxHeight: '100vh' }}
          >
            <div
              className={
                darkMode
                  ? 'has-text-light is-size-2 is-flex'
                  : 'has-text-dark is-size-2 is-flex'
              }
            >
              Precise Mind Report
            </div>
            {/* <img
              src={CompanyLogo}
              alt="Company Icon"
              style={{ height: '100%', width: '25vw' }}
            /> */}
          </Link>
        </div>

        <div id="navbarBasic" className="navbar-menu">
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="field is-grouped">
                <div className="control">
                  {!isAdmin ? (
                    <div className="is-flex-direction-column">
                      <div className="field">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={darkMode}
                            onChange={() => setDarkMode(!darkMode)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      {location.pathname.indexOf('/session') === -1 && <GoogleLogin
                        clientId="462340557471-bkvaqse20pqjtsbo4shar68gocuqusrf.apps.googleusercontent.com"
                        buttonText="Login as Admin!"
                        onSuccess={onLoginSuccess}
                        onFailure={onLoginFailure}
                        cookiePolicy={'single_host_origin'}
                        // className={
                        //   darkMode
                        //     ? 'button has-background-primary-dark has-text-light'
                        //     : 'button is-primary has-text-dark'
                        // }
                        render={(renderProps) => (
                          <Button type="success" click={renderProps.onClick} darkMode={darkMode} style={{ margin: "10% auto" }}>
                            Login
                          </Button>
                        )}
                      />}
                    </div>
                  ) : (
                    <div className="is-flex-direction-column">
                      <div className="field">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={darkMode}
                            onChange={() => setDarkMode(!darkMode)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div
                        className={
                          showDropdown ? 'dropdown is-active' : 'dropdown'
                        }
                        onClick={() => setShowDropdown(!showDropdown)}
                      >
                        <div className="dropdown-trigger">
                          <div
                            className={
                              darkMode
                                ? 'has-background-dark button is-secondary'
                                : 'has-background-light button is-secondary'
                            }
                            // className="button is-secondary"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                          >
                            {/* get name from admin state */}
                            <span
                              className={
                                darkMode ? 'has-text-light' : 'has-text-dark'
                              }
                            >
                              Logged In As: {name}{' '}
                            </span>
                            <span
                              className={
                                darkMode
                                  ? 'icon is-small has-text-light'
                                  : 'icon is-small has-text-dark'
                              }
                            >
                              <DropdownIcon />
                            </span>
                          </div>
                        </div>
                        <div
                          className="dropdown-menu"
                          id="dropdown-menu"
                          role="menu"
                        >
                          <div className={
                              darkMode
                                ? 'has-background-dark dropdown-content'
                                : 'has-background-light dropdown-content'
                            }>
                            <div className="dropdown-item" onClick={onLogoutSuccess}>
                              <GoogleLogout
                                clientId="462340557471-bkvaqse20pqjtsbo4shar68gocuqusrf.apps.googleusercontent.com"
                                onLogoutSuccess={onLogoutSuccess}
                                render={() => <p className={
                                  darkMode ? 'has-text-light' : 'has-text-dark'
                                }>Logout</p>}
                              ></GoogleLogout>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <hr className="navbar-divider m-0 p-0" style={{border: '1px solid #ccc',}} /> */}
      <div className="is-flex is-flex-direction-row">
        {isAdmin && <Menu showMenu={showMenu} setShowMenu={setShowMenu} darkMode={darkMode} />}
        <Navigation showMenu={showMenu} darkMode={darkMode} />
      </div>
    </>
  );
}

export default App;
