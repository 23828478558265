import React from 'react';
import { useEffect } from 'react';
// import { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FaBars as MenuIcon } from 'react-icons/fa';
// import { FaChevronRight as ArrowIconRight } from "react-icons/fa";
import { getSessionsList } from '../../features/admin/adminActions';
// import { adminActions } from '../../features/admin/adminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Spinner from '../uiUtils/Spinner';
import styles from './index.module.css';

const Index = ({ darkMode }) => {
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const dispatch = useDispatch();
  const sessionsList = useSelector((state) => state.admin.sessionsList); //get session list from admin store
  const status = useSelector((state) => state.admin.status);
  const token = useSelector((state) => state.auth.adminToken); //get token from admin store
  const headers = {
    'x-authtoken': token,
  }; //create header using token for api calls
  const plotData = [0, 0, 0];
  sessionsList.map((session) => {
    if (session.status === 'created') {
      plotData[0]++;
    } else if (session.status === 'started') {
      plotData[1]++;
    } else {
      plotData[2]++;
    }
  });

  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.color = darkMode ? '#fff' : '#000';

  const data = {
    labels: ['Not Started', 'In Progress', 'Completed'],
    datasets: [
      {
        label: '# of sessions',
        data: plotData,
        backgroundColor: [
          darkMode ? 'rgba(193, 37, 83, 0.8)' : 'rgba(255, 99, 132, 0.5)',
          darkMode ? 'rgba(0, 111, 179, 0.8)' : 'rgba(54, 162, 235, 0.5)',
          darkMode ? 'rgba(0, 138, 139, 0.8)' : 'rgba(75, 192, 192, 0.5)'
        ],
        borderColor: [
          darkMode ? 'rgba(193, 37, 83, 0.8)' : 'rgba(255, 99, 132, 0.5)',
          darkMode ? 'rgba(0, 111, 179, 0.8)' :'rgba(54, 162, 235, 0.5)',
          darkMode ? 'rgba(0, 138, 139, 0.8)' :'rgba(75, 192, 192, 0.5)'
        ],
        borderWidth: 1,
      },
    ]
  };

  useEffect(() => {
    if (isAdmin) {
      const data = { headers };
      dispatch(getSessionsList(data));
    }
  }, [isAdmin]);

  return (
    <div
      className={darkMode ? 'has-background-grey' : 'has-background-light'}
      style={{ width: '100vw'}}
      // style={
      //   showMenu
      //     ? {
      //         // width: '85vw',
      //         height: '100vh',
      //       }
      //     : {
      //         // width: '98vw',
      //         height: '100vh',
      //       }
      // }
    >
      {!isAdmin ? (
        <div style={{ height: '100vh', textAlign: 'center', width: '100vw' }}>
          <div
            className={
              darkMode
                ? `has-background-light ${styles.banner}`
                : `has-background-dark ${styles.banner}`
            }
          >
            <div
              className={
                darkMode
                  ? 'has-text-dark is-size-1'
                  : 'has-text-light is-size-1'
              }
            >
              ONE STOP FOR
            </div>
            <div
              className={
                darkMode
                  ? 'has-text-dark is-size-1'
                  : 'has-text-light is-size-1'
              }
            >
              MANAGING YOUR PATIENTS
            </div>
            <div className="tile is-ancestor m-auto is-flex-direction-row">
              <div
                className={
                  darkMode
                    ? `has-background-grey-light tile ${styles.tileSpecs}`
                    : `has-background-grey-lighter tile ${styles.tileSpecs}`
                }
              >
                <div className={darkMode ? `has-text-dark ${styles.textBlock}` : `has-text-dark ${styles.textBlock}`}>Experienced</div>
                <div className={darkMode ? `has-text-dark ${styles.textBlock}` : `has-text-dark ${styles.textBlock}`}>Doctors</div>
              </div>
              <div
                className={
                  darkMode
                    ? `has-background-grey-light tile ${styles.tileSpecs}`
                    : `has-background-grey-lighter tile ${styles.tileSpecs}`
                }
              >
                <div className={darkMode ? `has-text-dark ${styles.textBlock}` : `has-text-dark ${styles.textBlock}`}>Fast</div>
                <div className={darkMode ? `has-text-dark ${styles.textBlock}` : `has-text-dark ${styles.textBlock}`}>Analysis</div>
              </div>
              <div
                className={
                  darkMode
                    ? `has-background-grey-light tile ${styles.tileSpecs}`
                    : `has-background-grey-lighter tile ${styles.tileSpecs}`
                }
              >
                <div className={darkMode ? `has-text-dark ${styles.textBlock}` : `has-text-dark ${styles.textBlock}`}>Quick</div>
                <div className={darkMode ? `has-text-dark ${styles.textBlock}` : `has-text-dark ${styles.textBlock}`}>Report</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {status.getSessionsList === 'loading' && (
            <div
            className={
              darkMode
                ? `has-background-grey has-text-light ${styles.spinnerContainer}`
                : `has-background-light has-text-dark ${styles.spinnerContainer}`
            }
            >
              <Spinner />
            </div>
          )}
          {status.getSessionsList === 'success' &&
            sessionsList.length === 0 && (
              <div
                style={{
                  height: '100vh',
                  textAlign: 'center',
                  width: '85vw',
                  margin: 'auto',
                }}
              >
                <h1 className={darkMode ? 'has-text-light m-auto is-size-3' : 'has-text-dark m-auto is-size-3'}>No data available</h1>
              </div>
            )}
          {status.getSessionsList === 'error' && (
            <div
              style={{
                height: '100vh',
                textAlign: 'center',
                width: '100%',
                margin: 'auto',
              }}
            >
              <h1 className={darkMode ? 'has-text-light m-auto is-size-3' : 'has-text-dark m-auto is-size-3'}>Something went wrong :( </h1>
            </div>
          )}
          {status.getSessionsList === 'success' && sessionsList.length > 0 && (
            <div
              style={{
                height: '100vh',
                textAlign: 'center',
                width: '80vw',
                margin: '0',
                paddingTop: '2%',
              }}
            >
              <h1 className={darkMode ? 'has-text-light m-auto is-size-2' : 'has-text-dark m-auto is-size-2'}>Status of All Sessions</h1>
              <Doughnut
                data={data}
                style={{
                  margin: 'auto',
                  marginTop: '2%',
                  height: '30%',
                  textAlign: 'center',
                  width: '30vw'
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Index;
