import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAdmin: false,
    name: null,
    adminToken: null,
    isUser: false,
    loading: false,
    error: null,
  },
  reducers: {
    loginAdmin: (state, action) => {
      state.isAdmin = true;
      state.adminToken = action.payload.tokenId;
      state.name = action.payload.fullname;
    },
    logoutAdmin: (state) => {
      state.isAdmin = false;
      state.adminToken = null;
      state.name = null;
    },
    loginUser: (state) => {
      state.isUser = true;
    },
    logoutUser: (state) => {
      state.isUser = false;
    },
  },
});

export const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;
